import { observable, action } from "mobx";
import axios from "axios";

axios.defaults.headers.common = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

class AppSore {
  @observable index = null;
  @observable indexdata = null;
  @observable footerColumns = null;
  @observable searchBlock = null;
  @observable resourceConfig = null;
  @observable header = null;
  @observable openLogin = null;
  @observable sendURL = null;
  @observable filterPid = null;
  @observable dbResourceCount = 0;
  @observable journalResourceCount = 0;
  @observable journalResourceFilterCount = 0;
  @observable pJournalResourceCount = 0;
  @observable pJournalResourceFilterCount = 0;
  @observable ebookResourceCount = 0;
  @observable ebookResourceFilterCount = 0;
  @observable bookmarkCount = 0;
  @observable globalConfig = {};
  @observable loginRedirect = null;
  @observable checkedGuest = 0;
  @observable domain = "";

  constructor() {
    this.getIndex();
    this.getIndexData();
    this.getFooter();
    this.getHeader();
    this.getSearchBlock();
    this.getResourceConfig();
  }

  @action.bound
  async getIndex() {
    await axios
      .get("/file/data/index.json")
      .then((res) => {
        this.index = res.data;
      })
      .catch((error) => {
        //console.log("err=>" + error);
      });
    if (
      this.index === null ||
      this.index === undefined ||
      this.index.domainList === null ||
      this.index.domainList === undefined
    ) {
      this.index = {
        domainList: [],
      };
    }
  }

  @action.bound
  async getIndexData() {
    await axios
      .get("/file/data/indexdata.json")
      .then((res) => {
        this.indexdata = res.data;
      })
      .catch((error) => {
        //console.log("err=>" + error);
      });
  }

  @action.bound
  async getFooter() {
    await axios
      .get("/file/data/footer.json")
      .then((res) => {
        const { footerColumns } = res.data;
        this.footerColumns = footerColumns;
      })
      .catch((error) => {
        //console.log("err=>" + error);
      });
  }

  @action.bound
  async getHeader() {
    await axios
      .get("/file/data/header.json")
      .then((res) => {
        this.header = res.data;
      })
      .catch((error) => {
        //console.log("err=>" + error);
      });
  }

  @action.bound
  async getSearchBlock() {
    await axios
      .get("/file/data/searchBlock.json")
      .then((res) => {
        this.searchBlock = res.data;
      })
      .catch((error) => {
        //console.log("err=>" + error);
      });
  }

  async getResourceConfig() {
    await axios
      .get("/file/data/resource.json")
      .then((res) => {
        this.resourceConfig = res.data;
      })
      .catch((error) => {
        //console.log("err=>" + error);
      });
  }

  @action.bound
  setOpenLogin = (openLogin) => {
    this.openLogin = openLogin;
  };

  @action.bound
  setSendURL = (sendURL) => {
    this.sendURL = sendURL;
  };

  @action.bound
  setFilterPid = (filterPid) => {
    this.filterPid = filterPid;
  };

  @action.bound
  setDBResourceCount = (dbResourceCount) => {
    this.dbResourceCount = dbResourceCount;
  };

  @action.bound
  setJournalResourceCount = (journalResourceCount) => {
    this.journalResourceCount = journalResourceCount;
  };

  @action.bound
  setJournalResourceFilterCount = (journalResourceFilterCount) => {
    this.journalResourceFilterCount = journalResourceFilterCount;
  };

  @action.bound
  setPJournalResourceCount = (pJournalResourceCount) => {
    this.pJournalResourceCount = pJournalResourceCount;
  };

  @action.bound
  setPJournalResourceFilterCount = (pJournalResourceFilterCount) => {
    this.pJournalResourceFilterCount = pJournalResourceFilterCount;
  };

  @action.bound
  setEbookResourceCount = (ebookResourceCount) => {
    this.ebookResourceCount = ebookResourceCount;
  };

  @action.bound
  setEbookResourceFilterCount = (ebookResourceFilterCount) => {
    this.ebookResourceFilterCount = ebookResourceFilterCount;
  };

  @action.bound
  setBookmarkCount = (bookmarkCount) => {
    this.bookmarkCount = bookmarkCount;
  };

  @action.bound
  setGlobalConfig = (config) => {
    this.globalConfig = config;
  };

  @action.bound
  setLoginRedirect = (loginRedirect) => {
    this.loginRedirect = loginRedirect;
  };

  @action.bound
  setCheckedGuest = (checkedGuest) => {
    this.checkedGuest = checkedGuest;
  };

  @action.bound
  setDomain = (domain) => {
    this.domain = domain;
  };
}

export default AppSore;
